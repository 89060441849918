<!-- 销售站点主管账号 -->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <el-select v-model="param.sales_id" placeholder="业务员" clearable style="width:140px" @change="curr=1;getList()">
                <el-option v-for="(item,index) in salesList" :label="item.name" :value="item.id" :key="index"></el-option>
            </el-select>
            <!-- <el-date-picker style="width:230px" v-model="param.create_time" type="daterange" range-separator="至" start-placeholder="日志时间" end-placeholder="筛选" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="curr=1;getList()">
            </el-date-picker> -->
            <el-date-picker v-model="param.create_time" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="日志日期筛选" @change="curr=1;getList()"></el-date-picker> 
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list"  highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column prop="create_time" label="日期" align="center"></el-table-column>
            <el-table-column prop="sales_name" label="业务员" align="center"></el-table-column>
            <el-table-column prop="tel_num" label="今日电话量" align="center"></el-table-column>
            <el-table-column prop="goods_num" label="今日要货量" align="center"></el-table-column>
            <el-table-column prop="sale_num" label="今日销量" align="center"></el-table-column>
            <el-table-column prop="return_num" label="今日回款量" align="center"></el-table-column>
            <el-table-column prop="expect_return_num" label="明天预计回款量" align="center"></el-table-column>
            <el-table-column prop="new_cust_num" label="今日拜访新客户量" align="center"></el-table-column>
            <el-table-column prop="new_cust_content" label="今日拜访新客户内容" align="center"></el-table-column>
            <el-table-column prop="assist" label="需要工作协助" align="center"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="param.curr" :page-size="param.row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
        </div>
    </div>

</div>
</template>

<script>
import moment from 'moment';
export default {
    data: function () {
        return {
            param: {
                curr: 1, //页码
                row: 10, //每页条数
                create_time: moment().format('YYYY-MM-DD')
            },
            list: [],
            pages: 1, //总页数
            count: 0, //总条数
            salesList: []
        }
    },
    mounted: function () {
        this.getSelect();
        this.getList();

    },
    methods: {
        getSelect() {
            this.http.post('/admin.admin/getSalesList', {}, false).then(re => {
                this.salesList = re.data
            })
        },

        getList() {
            this.http.post('/sales.WorkLog/getLogList', this.param).then(re => {
                this.list = re.data.list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
            })
        },

        //翻页
        currentChange: function (curr) {
            this.param.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function (row) {
            this.param.row = row;
            this.getList();
        },
    }
}
</script>
